@import '../partials';
@import '../fonts/arquitecta/stylesheet';
@import 'node_modules/font-awesome/scss/font-awesome';
@import 'BaseStyles/body-copy';

html {
  box-sizing: border-box;
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  font-family: $sans-serif-font;

  // fixes overflow for chrome and safari
  position: relative;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  text-align: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $sans-serif-font;
  background-color: $white;
  color: $black;

  *::selection {
    background: transparentize(#fc408b, 0.5);
  }
}

.mainContent {

  html.single & {
    padding-top: rem(97);

    @include bp(lg) {
      padding-top: rem(212);
    }
  }
}

a {
  color: $black;
  background-color: transparent;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

.visually-hidden,
.visuallyhidden {
  @include visually-hidden;
}

img {
  max-width: 100%;
  height: auto;
}
.footer__logo img {
  max-height: 95px;
  max-width: 416px;
  width: auto;
  height: auto;
}
@media (min-width: 900px) {
  .footer__logo picture {
    margin-left: 3rem;
  }
}
@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .footer__logo img {
    max-height: 66px; /* Mobile size */
    max-width: 290px;
    width: auto;
    height: auto;
  }
}
.pageWrapper {
  overflow: hidden;
}

.button-link {
  &--arrow-right {
    @include text-link-arrow-right('white');

    &::after {
      width: rem(15);
      height: rem(24);
    }
  }
}

.page-content {
  .flyntComponent {
    @include component;
  }
}

// Reset plugin styles
.flyntComponent {

  .facetwp-counter {
    display: none;
  }

  .facetwp-checkbox {
    @include filter-checkbox;
  }

  .facetwp-facet {
    margin-bottom: 0;
  }

  .facetwp-sort-select,
  .facetwp-dropdown {
    @include dropdown-filter;

    &.fs-hidden,
    &.hidden {
      display: none;
    }
  }


  .facetwp-type-fselect {

    .fs-dropdown {
      margin-top: -1px;
    }

    .fs-label-wrap {
      @include dropdown-filter;
      background: none;
    }

    .fs-wrap {
      width: 100%;

      &.fs-open {
        .fs-arrow {
          transform: scaleY(-1);
          filter: FlipV;
        }
      }
    }

    .fs-arrow {
      background-image: url('../DocumentDefault/Assets/chevron-down.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 24px) center;
      border: none;
      width: 100%;
      height: 100%;
      right: 0;
    }

    .fs-dropdown {
      width: calc(100% - #{rem(48)});

      @include bp(xs3) {
        width: rem(400);
      }
    }
  }


  .facetwp-autocomplete {
    @include search-filter;
  }

  .facetwp-type-autocomplete {
    position: relative;
  }

  .facetwp-autocomplete-update {
    display: block;
    position: absolute;
    top: 1px;
    right: 1px;
    height: calc(100% - 2px);
    width: rem(60);
    background: $white;
    border: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../DocumentDefault/Assets/search-icon-black.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: rem(24);
    text-indent: rem(-999);
  }
}

[data-anim]:not([data-anim="elastic"]) {
  opacity: 0;
}

[data-anim="elastic"] {
  > div {
    opacity: 0;
  }
}
