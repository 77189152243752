@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaBlack.eot');
    src: url('../fonts/arquitecta/ArquitectaBlack.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaBlack.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaBlack.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaBlack.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaBlack.svg#ArquitectaBlack') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/Arquitecta-Italic.eot');
    src: url('../fonts/arquitecta/Arquitecta-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/Arquitecta-Italic.woff2') format('woff2'),
        url('../fonts/arquitecta/Arquitecta-Italic.woff') format('woff'),
        url('../fonts/arquitecta/Arquitecta-Italic.ttf') format('truetype'),
        url('../fonts/arquitecta/Arquitecta-Italic.svg#Arquitecta-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaHeavy.eot');
    src: url('../fonts/arquitecta/ArquitectaHeavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaHeavy.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaHeavy.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaHeavy.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaHeavy.svg#ArquitectaHeavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaMedium.eot');
    src: url('../fonts/arquitecta/ArquitectaMedium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaMedium.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaMedium.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaMedium.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaMedium.svg#ArquitectaMedium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaBlack-Italic.eot');
    src: url('../fonts/arquitecta/ArquitectaBlack-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaBlack-Italic.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaBlack-Italic.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaBlack-Italic.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaBlack-Italic.svg#ArquitectaBlack-Italic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaThin-Italic.eot');
    src: url('../fonts/arquitecta/ArquitectaThin-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaThin-Italic.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaThin-Italic.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaThin-Italic.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaThin-Italic.svg#ArquitectaThin-Italic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaBold.eot');
    src: url('../fonts/arquitecta/ArquitectaBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaBold.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaBold.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaBold.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaBold.svg#ArquitectaBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Arquitecta Book';
    src: url('../fonts/arquitecta/ArquitectaBook-Italic.eot');
    src: url('../fonts/arquitecta/ArquitectaBook-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaBook-Italic.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaBook-Italic.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaBook-Italic.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaBook-Italic.svg#ArquitectaBook-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaBold-Italic.eot');
    src: url('../fonts/arquitecta/ArquitectaBold-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaBold-Italic.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaBold-Italic.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaBold-Italic.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaBold-Italic.svg#ArquitectaBold-Italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaThin.eot');
    src: url('../fonts/arquitecta/ArquitectaThin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaThin.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaThin.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaThin.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaThin.svg#ArquitectaThin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/Arquitecta.eot');
    src: url('../fonts/arquitecta/Arquitecta.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/Arquitecta.woff2') format('woff2'),
        url('../fonts/arquitecta/Arquitecta.woff') format('woff'),
        url('../fonts/arquitecta/Arquitecta.ttf') format('truetype'),
        url('../fonts/arquitecta/Arquitecta.svg#Arquitecta') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaLight-Italic.eot');
    src: url('../fonts/arquitecta/ArquitectaLight-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaLight-Italic.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaLight-Italic.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaLight-Italic.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaLight-Italic.svg#ArquitectaLight-Italic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Arquitecta Book';
    src: url('../fonts/arquitecta/ArquitectaBook.eot');
    src: url('../fonts/arquitecta/ArquitectaBook.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaBook.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaBook.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaBook.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaBook.svg#ArquitectaBook') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaHeavy-Italic.eot');
    src: url('../fonts/arquitecta/ArquitectaHeavy-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaHeavy-Italic.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaHeavy-Italic.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaHeavy-Italic.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaHeavy-Italic.svg#ArquitectaHeavy-Italic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaLight.eot');
    src: url('../fonts/arquitecta/ArquitectaLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaLight.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaLight.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaLight.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaLight.svg#ArquitectaLight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Arquitecta';
    src: url('../fonts/arquitecta/ArquitectaMedium-Italic.eot');
    src: url('../fonts/arquitecta/ArquitectaMedium-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arquitecta/ArquitectaMedium-Italic.woff2') format('woff2'),
        url('../fonts/arquitecta/ArquitectaMedium-Italic.woff') format('woff'),
        url('../fonts/arquitecta/ArquitectaMedium-Italic.ttf') format('truetype'),
        url('../fonts/arquitecta/ArquitectaMedium-Italic.svg#ArquitectaMedium-Italic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/gothambook-webfont.eot');
  src: url('../fonts/gotham/gothambook-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham/gothambook-webfont.woff2') format('woff2'),
  url('../fonts/gotham/gothambook-webfont.woff') format('woff'),
  url('../fonts/gotham/gothambook-webfont.ttf') format('truetype'),
  url('../fonts/gotham/gothambook-webfont.svg#GothamBook') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/gothambookitalic-webfont.eot');
  src: url('../fonts/gotham/gothambookitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gotham/gothambookitalic-webfont.woff2') format('woff2'),
  url('../fonts/gotham/gothambookitalic-webfont.woff') format('woff'),
  url('../fonts/gotham/gothambookitalic-webfont.ttf') format('truetype'),
  url('../fonts/gotham/gothambookitalic-webfont.svg#GothamBook-Italic') format('svg');
  font-weight: 400;
  font-style: italic;
}
