@import '../partials';

.mce-content-body {
  p {
    @include content-body;

    &.intro-paragraph {
      @include intro-paragraph;
    }

    &.arrow-link {
      & > a {
        @include text-link-arrow-right;
      }
    }

    a {
      @include fix-long-urls;
      @include inline-link;
    }
  }

  h2, h3, h4, h5, h6 {
    @include body-header-base;
  }

  h2 {
    @include primary-body-header;
  }

  h3 {
    @include secondary-body-header;
  }

  ul, ol {
    // broswer resets
    margin: 0;
    padding: 0;

    padding-left: rem(30);
    @include content-body;
  }

  blockquote {
    @include quote-text;
    border-top: 5px solid $green;
    margin: 0;
    padding-top: 0.6em;
    margin-top: 1.2em;;

    p {
      @include quote-text;
    }
  }

  input[type=submit] {
    @include filter-apply-button;
  }

  input[type=text] {
    @include text-input;
  }
}